import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';

export const TOPIC_CARD_FRAGMENT = `
  fragment topicCardFragment on Topic {
    uid
    id
    schemaCode
    name
    imageFileResource {
      ...fileResourceBaseFragment
    }
    _followerCount
    _isFollowed(myUid: "%authUser%")
  }
  ${FILE_RESOURCE_BASE_FRAGMENT}
`;
